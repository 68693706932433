import React from "react";
import "./Contacto.css";
import { CiLinkedin, CiMail } from "react-icons/ci";

export default function Contacto({idioma}) {
    let email = "caicedojaime03z";

    function contactarAEmail() {
        window.open(`mailto:${email}@gmail.com`)
    }

    return (
        <div className="contacto">
            <div className="contacto-container">
                <p className="contacto-container-titulo-texto">{idioma ? "Get in touch" : "Ponte en contacto"}</p>
                <p className="contacto-container-info-titulo-texto">
                    {idioma ?"If my profile has caught your attention" : "Si mi perfil te ha llamado la atención puedes ponerte en"}
                </p>
                <p className="contacto-container-info-titulo-texto">
                    {idioma ? "you can contact me, I am always willing to listen" : "contacto conmigo, siempre estoy dispuesto a escuchar nuevas"}
                </p>
                <p className="contacto-container-info-titulo-texto">
                    {idioma ? "to new growth opportunities and my inbox" : "oportunidades de crecimiento, y mi bandeja de entrada"}
                </p>
                <p className="contacto-container-info-titulo-texto">
                    {idioma ? "is always open." : "siempre está abierta."}
                </p>
                <div className="contacto-container-info-boton-contenedor">
                    <button className="contacto-container-info-boton" onClick={() => window.open("https://www.linkedin.com/in/nanez-caicedo-jaime/")}>
                        <CiLinkedin />
                    </button>
                    <button className="contacto-container-info-boton" onClick={() => contactarAEmail()}>
                        <CiMail />
                    </button>
                </div>
            </div>
        </div>
    );
}
