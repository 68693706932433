import React from "react";
import "./ContenedorConocimiento.css";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";

export const ContenedorConocimiento = ({index, titulo, icono, texto}) => {
    const [conocimientoVisible, setConocimientoVisible] = React.useState(false);

    return (
        <>
            <div className="contenedor-conocimiento" id={index} >
                <div className="contenedor-conocimiento-top" style={{ borderBottom: conocimientoVisible ? "1px solid rgb(222, 222, 222)": "none" }}>
                    <div className="contenedor-conocimiento-logo">
                        <img src={icono} alt="logo conocimiento" width="40px" className="imgicon" />
                    </div>
                    <div className="contenedor-conocimiento-titulo">
                        <p className="contenedor-conocimiento-titulo-texto">{titulo}</p>
                    </div>
                    <div className="contenedor-conocimiento-logo">
                        <button className="contenedor-conocimiento-boton-logo-ocultar-conocimiento" onClick={() => setConocimientoVisible(!conocimientoVisible)}>
                            {conocimientoVisible ? <BsChevronDown /> : <BsChevronRight />}
                        </button>
                    </div>
                </div>
                {conocimientoVisible && <div className="contenedor-conocimiento-texto">
                    <div className="contenedor-conocimiento-texto-subcontenedor">
                        <p>{texto}</p>
                    </div>
                </div>}
            </div>
        </>
    )
}