import React from "react";
import "./AcercaDeMiExtra.css";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import TimeLineDemo from "../../componentes/timeline/TimeLineDemo";
import { useLocation, useNavigate } from 'react-router-dom';
import FotoPresentacion from "../../recursos/foto-presentacion.jpg";
import { PiBag } from "react-icons/pi";
import { VscGithubAlt } from "react-icons/vsc";
import resume from "../../recursos/resume.pdf";
import curriculo from "../../recursos/curriculo.pdf";

export default function AcercaDeMiExtra({cambiarControlador}) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const idioma = (params.get('lng') === "true") ? true : false;

    function descargarCurriculo() {
        if (idioma) {
            window.open(resume, "_blank");
        } else {
            window.open(curriculo, "_blank");
        }
    }

    return (
        <div className="acerca-de-mi-extra">
            <div className="acerca-de-mi-extra-top-contenedor">
                <span className="acerca-de-mi-extra-top-boton-contenedor" onClick={()=>{ navigate("/");cambiarControlador()}}>
                    <BsArrowLeftSquareFill className="acerca-de-mi-extra-top-boton" />
                </span>
                <p className="acerca-de-mi-extra-top-texto"> {idioma ? "About me" : "Acerca de mi"}</p>
            </div>
            <div className="acerca-de-mi-extra-bottom-contenedor">
                <div className="acerca-de-mi-extra-subbottom-izq">
                    <TimeLineDemo idioma={idioma} />
                </div>
                <div className="acerca-de-mi-extra-subbottom-der">
                    <div className="acerca-de-mi-extra-subbottom-foto-contenedor">
                        <img src={FotoPresentacion} alt="foto de perfil" className="acerca-de-mi-foto-presentacion" />
                    </div>
                    <div className="acerca-de-mi-extra-subbottom-iconos-contenedor">
                        <PiBag className="acerca-de-mi-extra-iconos left-pf-icon" onClick={descargarCurriculo}/>
                        <VscGithubAlt className="acerca-de-mi-extra-iconos" onClick={() => window.open("https://github.com/Jaime2003z")} />
                    </div>
                </div>
            </div>
        </div>
    )
}