import React from "react";
import "./TimeLineDemo.css";
import { BsCheckCircleFill } from "react-icons/bs";

export default function TimeLineDemo({ idioma }) {
    const TimeLineDescripciones = [
        {
            es: {
                titulo: "Julio 2023",
                descripcion:
                    "Soy contratado por Neex Corp una empresa que desarrolla software de cobranza, mi puesto tiene asignado el desarrollo, soporte y mantenimiento de sus productos software, por lo cual he obtenido experiencia real sobre las necesidades de los clientes a nivel de frontend y backend.",
            },
            en: {
                titulo: "July 2023",
                descripcion:
                    "I am hired by Neex Corp, a company that develops collection software, my position is assigned the development, support and maintenance of its software products, for which I have obtained real experience on the needs of clients at the frontend and backend level.",
            },
        },
        {
            es: {
                titulo: "Junio 2022",
                descripcion:
                    "Realicé el curso gratis de backend que tiene oracle en compañia con alura, fue donde aprendi a usar Spring para el desarrollo.",
            },
            en: {
                titulo: "June 2023",
                descripcion:
                    "I took the free backend course that Oracle has in company with Alura, it was where I learned to use Spring for development.",
            },
        },
        {
            es: {
                titulo: "Febrero 2023",
                descripcion:
                    "En clase de desarrollo de software en la universidad, trabajamos en grupos de 5 estudiantes para una empresa de programación local (OL software) la cual necesitaba que desarrollaramos una aplicación web para la gestión de sus empleados, lo cual hicimos usando mySql, NodeJS y ReactJs, para dicho proyecto mi aporte más significativo fue la pagina de inicio, la validación de credenciales, el manejo de las vistas en el software dependiendo de los roles, crear el dashboard, el desarrollo y logica de la barra de navegación, etc.",
            },
            en: {
                titulo: "February 2023",
                descripcion:
                    "In a software development class at university, we worked in groups of 5 students for a local programming company (OL software) which needed us to develop a web application to manage their employees, which we did using mySql, NodeJS and ReactJs For this project, my most significant contribution was the home page, the validation of credentials, the management of views in the software depending on the roles, creating the dashboard, the development and logic of the navigation bar, etc.",
            },
        },
        {
            es: {
                titulo: "Febrero 2020",
                descripcion:
                    "Recibí una beca de la Universidad Autónoma de Occidente en Cali, luego de graduarme del bachillerato, razón por la cual comencé mis estudios universitarios (ingenieria informática).",
            },
            en: {
                titulo: "February 2020",
                descripcion:
                    "I received a scholarship from the Universidad Autónoma de Occidente in Cali, after graduating from high school, which is why I began my university studies (computer engineering).",
            },
        },
        {
            es: {
                titulo: "Noviembre 2019",
                descripcion:
                    "Me gradué de bachillerato como técnico en informática, además obtuve el puntaje más alto de las pruebas ICFES entre mi promoción en la institución educativa Santa Rosa.",
            },
            en: {
                titulo: "November 2019",
                descripcion:
                    "I graduated from high school as a computer technician, and I also obtained the highest score on the ICFES tests among my class at the Santa Rosa educational institution.",
            },
        },
    ];

    return (
        <div className="timeline-content-principal">
            {TimeLineDescripciones.map((item, index) => (
                <div key={index} className="timeline-content">
                    <BsCheckCircleFill className="check-icon" />
                    <div className="timeline-content-text">
                        <div className="timeline-top-content-text">
                            <p>{item[idioma ? "en" : "es"].titulo}</p>
                        </div>
                        <div className="timeline-bottom-content-text">
                            <p>{item[idioma ? "en" : "es"].descripcion}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
