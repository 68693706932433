import React from 'react';
import './App.css';
import AcercaDeMi from './paginas/acercaDeMi/AcercaDeMi';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Conocimientos from './paginas/conocimientos/Conocimientos';
import Contacto from './paginas/contacto/Contacto';
import AcercaDeMiExtra from './paginas/acercaDeMiExtra/AcercaDeMiExtra';
import { BsArrowLeftRight } from "react-icons/bs";

function App() {
  const navigate = useNavigate();
  const [idioma, setIdioma] = React.useState(false);

  const cambiarControlador = () => {
    let contenedorBotones = document.getElementsByClassName("acerca-menu-flotante");
    contenedorBotones[0].style.display = "flex";
    cambiarColorBoton("/")
  }
  
  React.useEffect(() => {
    cambiarColorBoton(window.location.pathname);
  }, [])


  const ocultarMenuSuperior = () => {
    let contenedorBotones = document.getElementsByClassName("acerca-menu-flotante");
    contenedorBotones[0].style.display = "none";
  }

  const cambiarColorBoton = (e) => {
    let botonAcerca = document.getElementById("boton-acerca");
    let botonKnowledge = document.getElementById("boton-knowledge");
    let botonContact = document.getElementById("boton-contact");
    let botonIdioma = document.getElementById("boton-idioma");

    if (e === "/") {
      botonAcerca.style.color = "rgb(25, 78, 225)";
      botonKnowledge.style.color = "white";
      botonContact.style.color = "white";
      botonIdioma.style.color="white";
    }
    else if (e === "/knowledge") {
      botonAcerca.style.color = "rgb(16, 24, 46)";
      botonKnowledge.style.color = "rgb(25, 78, 225)";
      botonContact.style.color = "rgb(16, 24, 46)";
      botonIdioma.style.color="rgb(16, 24, 46)";
    }
    else if (e === "/contact") {
      botonAcerca.style.color = "rgb(16, 24, 46)";
      botonKnowledge.style.color = "rgb(16, 24, 46)";
      botonContact.style.color = "rgb(25, 78, 225)";
      botonIdioma.style.color="rgb(16, 24, 46)";
    }
    else {
      let contenedorBotones = document.getElementsByClassName("acerca-menu-flotante");
      contenedorBotones[0].style.display = "none";
    }
  }

  return (
    <div className='App'>
      <div className='acerca-menu-flotante'>
        <button className="custom-toggle-switch" id='boton-idioma' onClick={() => { setIdioma(!idioma) }}>
          <p>En</p>
          <BsArrowLeftRight />
          <p>Es</p>
        </button>
        <button id='boton-acerca' className='acerca-menu-flotante-botones-navegacion' onClick={() => { cambiarColorBoton("/"); navigate('/') }}>
          {idioma ? 'About.' : 'Acerca.'}
        </button>
        <button id='boton-knowledge' className='acerca-menu-flotante-botones-navegacion' onClick={() => { cambiarColorBoton("/knowledge"); navigate('/knowledge') }}>
        {idioma ? 'Knowledge and experience.' : 'Conocimientos y experiencia.'}
        </button>
        <button id='boton-contact' className='acerca-menu-flotante-botones-navegacion' onClick={() => { cambiarColorBoton("/contact"); navigate('/contact') }}>
        {idioma ? 'Contact.' : 'Contacto.'}
        </button>
      </div>
      <Routes>
        <Route exact path='/' element={<AcercaDeMi ocultarMenuSuperior={ocultarMenuSuperior} idioma={idioma}/>} />
        <Route exact path='/knowledge' element={<Conocimientos idioma={idioma} />} />
        <Route exact path='/contact' element={<Contacto idioma={idioma} />} />
        <Route exact path='/about' element={<AcercaDeMiExtra cambiarControlador={cambiarControlador} />} />
      </Routes>
    </div>
  );
}

export default App;
