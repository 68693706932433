import React from "react";
import { Link } from "react-router-dom";
import "./AcercaDeMi.css";
import ParticlesComponent from "../../componentes/particles/particles";

export default function AcercaDeMi({ ocultarMenuSuperior, idioma }) {
    return (
        <div className="acercaDeMi">
            <ParticlesComponent />
            <div className="acerca-presentacion-info">
                <div className="acerca-presentacion-info-textos-contenedor">
                    <p className="acerca-presentacion-info-texto-menor">
                        {idioma ? "Hi, my name is" : "Hola, me llamo"}
                    </p>
                    <p className="acerca-presentacion-info-texto-mayor">
                        {idioma ? "James Caicedo" : "Jaime Caicedo"}
                    </p>
                    <p className="acerca-presentacion-info-texto-menor">
                        {idioma
                            ? "and I'm a junior programmer."
                            : "y soy programador junior."}
                    </p>
                </div>
                <Link
                    to={`/about?lng=${idioma}`}
                    onClick={ocultarMenuSuperior}
                    className="acerca-presentacion-info-button"
                >
                    {idioma ? "About me" : "Acerca de mí"}
                </Link>
            </div>
        </div>
    );
}
