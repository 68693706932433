import React from "react";
import "./Conocimientos.css";
import { ContenedorConocimiento } from "../../componentes/contenedorCocimiento/ContenedorConocimiento";
import NoSQL from "../../recursos/nosql_logo.png"

export default function Conocimientos({ idioma }) {
    const conocimientos = [
        {
            icono:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1200px-HTML5_logo_and_wordmark.svg.png",
            titulo: "HTML5 (HTML, CSS, JS)",
            es: {
                texto:
                    "Aprendí HTML5 a mediados del 2021 motivado porque en mi carrera ya habían compañeros que trabajaban desarrollando paginas por lo que de manera autodidacta comencé a construir mis primeras paginas web estáticas cosa que me ayudo a entender mucho sobre internet, sobre como maquetar paginas, etc.",
            },
            en: {
                texto:
                    "I learned HTML5 in mid-2021 motivated because in my career there were already colleagues who worked developing pages, so I self-taught myself began to build my first static web pages, which helped me understand a lot about the internet, how to layout pages, etc.",
            },
        },
        {
            icono: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
            titulo: "React JS",
            es: {
                texto:
                    `En febrero de 2023 tuve que aprender a utilizar esta biblioteca de javascript ya que de proyecto para la materia de desarrollo de software 1 trabajabamos desarrollando una webapp para una empresa de programación local (OL software) fue la primera vez que realicé una página web no estatica, que realizaba peticiones al backend, esta experiencia me hizo entender mucho más del desarrollo de software de lo que ya sabía, todo guiado por un excelente profesor en programación.

                    He adquirido mucha destreza con esta tecnología ya que despues de haber trabajado con ella una vez, comencé a desarrollar todos mis proyectos preferencialmente con ella, incluso aprendí a usar React Native para el desarrollo de aplicaciones móviles para android y ios.
                    
                    Es la tecnología a la cual le he dedicado mas tiempo de ocio entre todas con las cuales he programado, de hecho este portafolio esta construido con react.`,
            },
            en: {
                texto:
                    `In February 2023 I had to learn to use this javascript library since as a project for the software development subject 1 we were working on developing a webapp for a local programming company (OL software) it was the first time I made a non-static web page , who made requests to the backend, this experience made me understand much more about software development than I already knew, all guided by an excellent programming teacher.

                    I have acquired a lot of skill with this technology since after having worked with it once, I began to develop all my projects preferably with it, I even learned to use React Native for the development of mobile applications for Android and iOS.
                    
                    It is the technology to which I have dedicated the most leisure time among all those with which I have programmed, in fact this portfolio is built with react.`,
            },
        },
        {
            icono: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Angular_full_color_logo.svg",
            titulo: "Angular",
            es: {
                texto:
                    `Estuve trabajando 3 meses con esta tecnología ya que en mi trabajo me pidieron desarrollar en un proyecto de software nuevo, por lo cual tuve que aprender a desarrollar con Angular cosa que no se me dificulto ya que tras desarrollar con HTML y JavaScript las tecnologías basadas en ello son fáciles de migrar, no pude tomar más experiencia de esta tecnología ya que a falta de programadores en la empresa me trasladaron al área de soporte.`,
            },
            en: {
                texto:
                    `I was working for 3 months with this technology since at work they asked me to develop a new software project, so I had to learn to develop with Angular, which was not difficult for me since after developing the technologies based on HTML and JavaScript They are easy to migrate, I was not able to gain more experience with this technology since due to a lack of programmers in the company they transferred me to the support area.`,
            },
        },
        {
            icono: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg",
            titulo: "NodeJS",
            es: {
                texto:
                    `Aprendí de backend con JavaScript y NodeJs a principios de 2022 cuando vi estructura de datos y algoritmos, clase en la cual debíamos aprender a manejar peticiones con NodeJs, desde ahí he manejado esta estructura de backend solo para los proyectos universitarios o de desarrollo personales.`,
            },
            en: {
                texto:
                    `I learned about backend with JavaScript and NodeJs at the beginning of 2022 when I saw data structure and algorithms, a class in which we had to learn to manage requests with NodeJs, from there I have managed this backend structure only for university or personal development projects.`,
            },
        },
        {
            icono: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Sql_database_shortcut_icon.png",
            titulo: "SQL",
            es: {
                texto:
                    `A finales de 2022, en una clase de bases de datos relacionales en mi universidad, aprendí sobre SQL y cómo utilizar herramientas como MySQL y PostgreSQL. Este tipo de base de datos ha sido mi favorita frente a NoSQL, por eso la he integrado en todos mis proyectos personales. A parte también tengo experiencia ya que la empresa Neex Corp utiliza este tipo de bases de datos y me ha tocado trabajar con ellas.`,
            },
            en: {
                texto:
                    `In late 2022, in a relational database class at my university, I learned about SQL and how to use tools like MySQL and PostgreSQL. This type of database has been my favorite over NoSQL, which is why I have integrated it into all my personal projects. I also have experience since the company Neex Corp uses this type of databases and I have had to work with them.`,
            },
        },
        {
            icono: NoSQL,
            titulo: "NoSQL",
            es: {
                texto:
                    `Este es el tipo de bases de datos con el que menos experiencia tengo porque solo las he usado dentro de la universidad y solo en 2 clases, fuera de ellas realmente no las he usado, pero entre todas solo he usado Firebase, AuraDB. y MongoDB.`,
            },
            en: {
                texto:
                    `This is the type of databases with which I have the least experience because I have only used them within the university and only in 2 classes, outside of them I have not really used them, but among all I have only used Firebase, AuraDB. and MongoDB.`,
            },
        },
        {
            icono: "https://upload.wikimedia.org/wikipedia/en/3/30/Java_programming_language_logo.svg",
            titulo: "Java",
            es: {
                texto:
                    `Vi este lenguaje en programación orientada a objetos por primera vez a principios de 2021 en la Universidad. Me pareció muy curioso cómo con este lenguaje se podían crear objetos con características y acciones, por eso decidí aprender de él de forma independiente para hacer aplicaciones móviles. Gracias a este lenguaje mi lógica de programación mejoró mucho, por eso aprendí backend con este lenguaje y a usar frameworks como spring e hibernate. Actualmente utilizo este lenguaje en la empreso en la cual estoy trabajando.`,
            },
            en: {
                texto:
                    `I saw this language in object-oriented programming for the first time in early 2021 at the University. I found it very curious how with this language you could create objects with characteristics and actions, that's why I decided to learn about it independently to make mobile applications. Thanks to this language my programming logic improved a lot, that's why I learned backend with this language and how to use frameworks like spring and hibernate. I currently use this language in the company I am working for.`,
            },
        },
        {
            icono: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg",
            titulo: "Python",
            es: {
                texto:
                    `Fue el primer lenguaje que aprendí a usar en la universidad, en algoritmia y programación, aunque nunca he desarrollado aplicaciones con este lenguaje ni usado sus frameworks, ocasionalmente lo uso para automatizar tareas repetitivas o realizar cálculos automáticos.`,
            },
            en: {
                texto:
                    `It was the first language I learned to use at university, in algorithms and programming, although I have never developed applications with this language or used its frameworks, I occasionally use it to automate repetitive tasks or perform automatic calculations.`,
            },
        },
    ];

    return (
        <div className="conocimientos">
            <div className="conocimientos-container-organizador">
                <div className="conocimientos-container-organizador-dos">
                    {conocimientos.map((conocimiento, index) => (
                        <ContenedorConocimiento
                            key={index}
                            titulo={conocimiento.titulo}
                            icono={conocimiento.icono}
                            texto={idioma ? conocimiento.en.texto : conocimiento.es.texto}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
